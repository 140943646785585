import React, { useContext } from "react";
import styles from "./triviacategory.module.css";

import triviaicon from "../../assets/gulder_assets/scramble.svg";

import timericon from "../../assets/images/timericon.png";
// import Cost from "../Cost";

import { useState } from "react";
import Payments from "../../components/Payments";
import axios from "axios";
import { isAuthenticated } from "../../utils/authdata";
import Modal from "../../components/Modal";

import PaymentSuccess from "../../components/PaymentSucess/success";
import { StatsContext } from "../../context/";
import Spinner from "../../components/Spinner";
import { message } from "antd";

const Scramble = ({ setShowScramble, timeVal, getUplayScramble }) => {
  const [topUp, setTopUp] = useState(false);
  const [userToken, setUserToken] = useState("");
  const { token } = isAuthenticated();
  const [success, setSuccessModal] = useState(false);
  const [amountAdded, setAmmountAdded] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [scrambeCategory, setScrambleCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    state: { gameSettings,scramblecategory },dispatch
  } = useContext(StatsContext);
  const fetchUserStat = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PAYMENT_URL}/api/points/user-stat`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUserToken(response?.data?.TOKEN);
    } catch (error) {}
  };

  const getScrambleCatgory = async () => {
    setLoading(true)
   
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_GUESS_GAME_URL}/crossword-category`,
        {
          headers: { Authorization: `${token}` },
        }
      );
    
      const scrambles = result?.data?.obj?.crossWordCategory;
      setScrambleCategory(scrambles);
      dispatch({
        type:'ADD_SCRAMBLE_CATEGORY',
        payload:scrambles
      })
      
      setLoading(false)
    } catch (err) {
      if(err instanceof Error) {
        if(err.message == 'Network Error'){
          message.error('Network Error, Please checked your network')
        }
        if(err.response){
          if(err?.response){
            message.error('An error occurred please try again later')
          }
        }
      }
      setLoading(false)
    }
  };
  React.useEffect(() => {
    fetchUserStat();
    getScrambleCatgory();
  }, []);
  const playNow = (cat) => {
    if (userToken == 0) {
      setTopUp(true);
    } else {
      setShowScramble((scramble) => !scramble);
      getUplayScramble(cat);
      
    }
  };

  return (
    <div>
      <div className={styles.topwrapper}>
        <img
          className={`${styles.image_cat}`}
          src={triviaicon}
          alt="triviaicon"
        />
        <div className={styles.title}>Scramble games</div>
        <div>How Fast can you think ?</div>
        <br />
        {/* <span style={{fontSize: '16px',
    padding: '15px', marginBottom: '14px'
}} className={`text-danger`}>Cost 5 tokens  per game</span> */}
      </div>
      <div className={styles.catstory}>
        <img
          src={timericon}
          alt="timericon"
          className={`${styles.trivia_timer}`}
          style={{ marginTop: "20px" }}
        />
        <div className={styles.readme}>
      
          You have {timeVal / 1000} secs to unscramble word and game costs{" "}
          {gameSettings?.gameEntryCosts
            ? gameSettings?.gameEntryCosts[0]?.value
            : ""}{" "}
          Tokens
        </div>
        {/* <button disabled={confirm} onClick={playNow} className={styles.btn}>
          play now
        </button> */}

        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Spinner />
          </div>
        ) : (
          <>
            {scramblecategory.length ? (
              <div  className={`${styles.categorywrapper}`}>
                {scrambeCategory?.map((cat) => {
                  
                  return (
                    <div
                      onClick={() => playNow(cat)}
                      className={`${styles.categorycard}`}
                    >
                      <div
                        className={`d-flex justify-content-center align-items-center flex-column`}
                      >
                        <img style={{ width: 60 }} src={cat?.imgUrl} />
                        <div className={`${styles.card_title}`}>
                          {cat?.name}
                        </div>
                        <p className={`${styles.card_description}`}>
                          {cat?.description}
                          
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div style={{height: 35,
    marginTop: 31,
    fontSize: 'large'}} className="d-flex justify-content-center">
                Category not found
              </div>
            )}
          </>
        )}

       
      </div>
      {/* {topUp && (
        <TopModal
          modalClass={"topup_modal_content"}
          dialogClassName={"topUpclass"}
          closeModal={() => setTopUp(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <Payments
            GetTrivias={GetTrivias}
            setAmmountAdded={setAmmountAdded}
            setTopUp={setTopUp}
            setSuccessModal={setSuccessModal}
            successModal={successModal}
          />
        </TopModal>
      )} */}
      {/* {successModal && (
        <TopModal
          modalClass={"sss"}
          dialogClassName={"topUpsuccess"}
          closeModal={() => setSuccessModal(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <div className="text-center mb-4">
            
            <FeatherIcon icon='check-circle' size="50px" color="#198754"/>
          </div>
          <div className={styles.tokenAdded}> 
              
              {formatNumber(amountAdded ? amountAdded / 10 : "")} Token(s)
          </div>
          <div
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "bolder",
            }}
            className={`${styles.text} mt-4 mb-4 text-center` } style={{fontSize:'1rem', fontWeight:'400'}}
          >
             has been added to your wallet
          </div>

          <div className=" d-flex mt-4 mb-4 justify-content-center">
            <button onClick={()=>{
              setSuccessModal(false)
              showTriviabox(true);
              GetTrivias();
              
            }}  className={styles.topupsuccess}>Play Game</button>
          </div>
        </TopModal>

      )} */}
      {topUp && (
        <Modal
          setTopUp={setTopUp}
          modalClass={"sss"}
          dialogClassName={"topUpsuccess"}
          closeModal={() => setTopUp(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <Payments
            setAmmountAdded={setAmmountAdded}
            setSuccessModal={setSuccessModal}
            setTopUp={setTopUp}
            fetchUserStat={fetchUserStat}
            setConfirm={setConfirm}
          />
        </Modal>
      )}
      {success && (
        <Modal
          modalClass={"sss"}
          dialogClassName={"topUpsuccess"}
          closeModal={() => setSuccessModal(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <PaymentSuccess
            setSuccessModal={setSuccessModal}
            amountAdded={amountAdded}
          />
        </Modal>
      )}
      {/* {topUp && (
        <Modalb
          setTopUp={setTopUp}
          closeModal={() => setTopUp(false)}
          space={{ maxWidth: "776px" }}
          cancelIcon={true}
        >
          <Payments setTopUp={setTopUp} />
        </Modalb>
      )} */}
    </div>
  );
};

export default Scramble;
//
